import React from "react";
import blog1 from "../assets/images/blog-1-1.jpg";
import useLatestArticles from "../Hooks/useLatestArticles";
import {Link} from "../../.cache/gatsby-browser-entry";
import BlogCard from "./BlogCard";

const BlogThree = () => {

  const latestBlog = useLatestArticles()
  return (
      <section className="blog-one  blog-one__home-two">
        <div className="container">
          <div className="block-title text-center">
            <h2 className="block-title__title">
              Últimas publicaciones
            </h2>
          </div>
          <div className="row">
            {latestBlog.map(article =>
                <BlogCard article={article}/>
            )}
          </div>
        </div>
      </section>
  );
};

export default BlogThree;
