import React from 'react';
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import NavThree from "../components/NavThree";
import SliderOne from "../components/SliderOne";
import CourseCatThree from "../components/CourseCatThree";
import TeamTab from "../components/TeamTab";
import VideoThree from "../components/VideoThree";
import CallToActionFive from "../components/CallToActionFive";
import BlogThree from "../components/BlogThree";
import IndexIntroduction from "../components/IndexIntroduction";


const HomePage = () => {
    return (
        <Layout
            title={"OshyTech"}
            metaDescription={ " Mi rincón dónde explicarte todo lo que se y aprendo de desarrollo " }
            image={null}
            keywords={""}>
            <NavThree active={""}/>
            <SliderOne/>
            <IndexIntroduction/>
            <BlogThree/>
            <TeamTab/>
            <CourseCatThree/>
            <CallToActionFive/>
            <VideoThree/>
            <Footer/>
        </Layout>
    );
};

export default HomePage;

