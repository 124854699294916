import React from "react";
import oshyTechXl from "../assets/images/oshytech_xl.webp";
import logoRolteca from "../assets/images/logo-rolteca.png";
import logoBloodbowl from "../assets/images/bloodbowl-logo.png";
import logoPython from "../assets/images/logo-python.png";

const TeamTab = () => {
  return (
      <section className="team-tab">
        <div className="container">
          <div className="tab-content">
            <div
                className="tab-pane show active  animated fadeInUp"
                role="tabpanel"
                id="overview"
            >
              <div className="row">
                <div className="col-lg-6 d-flex">
                  <div className="my-auto">
                    <div className="team-tab__content">
                      <div className="team-tab__top">
                        <div className="team-tab__left">
                          <h2 className="team-tab__title">Progreso de los proyectos</h2>
                          <p className="team-tab__designation">loading....</p>
                        </div>
                      </div>
                      <p className="team-tab__text">
                        La tecnología existe para simplificar la vida de las personas, juntos aprenderemos cómo podemos
                        hacerlo posible
                      </p>
                      <div className="progress-one__wrap">
                        <div className="progress-one__single">
                          <div className="progress-one__top">
                            <h3 className="progress-one__title">Rolteca</h3>
                            <h3 className="progress-one__percent">
                              <span className="counter">85</span>%
                            </h3>
                          </div>
                          <div className="progress-one__bar">
                          <span
                              style={{width: `85%`}}
                              className="wow slideInLeft"
                          />
                          </div>
                        </div>
                        <div className="progress-one__single">
                          <div className="progress-one__top">
                            <h3 className="progress-one__title">Curso de Python</h3>
                            <h3 className="progress-one__percent">
                              <span className="counter">30</span>%
                            </h3>
                          </div>
                          <div className="progress-one__bar">
                          <span
                              style={{width: `30%`}}
                              className="wow slideInLeft"
                          />
                          </div>
                        </div>
                        <div className="progress-one__single">
                          <div className="progress-one__top">
                            <h3 className="progress-one__title">BloodBowl Codex v.2</h3>
                            <h3 className="progress-one__percent">
                              <span className="counter">0</span>%
                            </h3>
                          </div>
                          <div className="progress-one__bar">
                          <span
                              style={{width: `0%`}}
                              className="wow slideInLeft"
                          />
                          </div>
                        </div>
                      </div>

                      <a href="#none" className="thm-btn team-tab__btn">
                        Ver más
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <img src={oshyTechXl} alt=""/>
                </div>
              </div>
            </div>
            <div
                className="tab-pane  animated fadeInUp"
                role="tabpanel"
                id="curriculum"
            >
            </div>
          </div>
          <ul className="team-tab__tab-navs list-unstyled nav nav-tabs">
            <li>
              <a className="active" role="tab" data-toggle="tab" href="#overview">
                <img src={logoRolteca} className="background-image" alt="Rolteca"/>
              </a>
            </li>
            <li>
              <a className="active" role="tab" data-toggle="tab" href="#curriculum">
                <img src={logoPython} className="background-image" alt="Curso de pythonw"/>
              </a>
            </li>
            <li>
              <a className="active" role="tab" data-toggle="tab" href="#curriculum">
                <img src={logoBloodbowl} className="background-image" alt="BloodBowlCodex"/>
              </a>
            </li>
          </ul>
        </div>
      </section>
  );
};

export default TeamTab;
