import {graphql, useStaticQuery} from "gatsby"


const useLatestArticles = () => {
    const response = useStaticQuery(
        graphql`
            query latestsArticles {
                allStrapiArticles(limit: 3, sort: {fields: updated_at, order: DESC}) {
                    edges {
                        node {
                            id
                            slug
                            title
                            shortdescription
                            image {
                                localFile{
                                    sharp: childImageSharp {
                                        fluid( maxWidth: 307, maxHeight: 307, quality: 100) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return response.allStrapiArticles.edges.map(it => it.node);
}

export default useLatestArticles;
