import blog1 from "../assets/images/blog-1-1.jpg";
import {Link} from "../../.cache/gatsby-browser-entry";
import React from "react";
import Img from "gatsby-image"

const BlogCard = ({article}) => {
    return (
        <div key={article.id} className="col-lg-4">
            <div className="blog-one__single">
                <div className="blog-one__image">
                    <Img
                        fluid={{ ...article.image.localFile.sharp.fluid,aspectRatio: 9 / 11 }}
                        alt={article.title} />
                    <Link className="blog-one__plus" to={article.slug}>
                        <i className="kipso-icon-plus-symbol"></i>
                    </Link>
                </div>
                <div className="blog-one__content text-center">
                    <h2 className="blog-one__title">
                        <a href={article.slug}>
                            {article.title}
                        </a>
                    </h2>
                    <p className="blog-one__text text-justify">
                        {article.shortdescription}
                    </p>
                    <Link to={article.slug} className="blog-one__link">
                        Leer más
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default BlogCard;
