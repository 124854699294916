import React, {Component} from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import about1 from "../assets/images/about-1-1.jpg";


export default class IndexIntroduction extends Component {
    constructor() {
        super();
        this.state = {
            startCounter: false
        };
    }

    onVisibilityChange = (isVisible) => {
        if (isVisible) {
            this.setState({startCounter: true});
        }
    };

    render() {
        return (
            <section className="about-two">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-two__content">
                                <div className="block-title text-left">
                                    <h1 className="block-title__title">
                                        Bienvenido a OshyTech <br/>
                                    </h1>
                                </div>
                                <p className="about-two__text text-justify">
                                    En el ordenador hago tres cosas, programar, opinar y enseñar,
                                    en esta web podrás encontrar las tres de forma bastante ordenada.
                                    En youtube verás contenido sobre programación y en Twitter opiniones y consejos
                                </p>
                                <div className="about-two__single-wrap">
                                    <div className="about-two__single">
                                        <div className="about-two__single-icon">
                                            <i className="kipso-icon-professor"></i>
                                        </div>
                                        <div className="about-two__single-content">
                                            <p className="about-two__single-text">
                                                Cursos y consejos de dessarrollo
                                            </p>
                                        </div>
                                    </div>
                                    <div className="about-two__single">
                                        <div className="about-two__single-icon">
                                            <i className="kipso-icon-knowledge"></i>
                                        </div>
                                        <div className="about-two__single-content">
                                            <p className="about-two__single-text">
                                                Opiniónes sobre el mundo geek
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <a href="#none" className="thm-btn">
                                    Suscribirte
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-6 d-flex justify-content-xl-end justify-content-sm-center">
                            <div className="about-two__image">
                                <span className="about-two__image-dots"></span>
                                <img src={about1} alt=""/>
                                <div className="about-two__count">
                                    <div className="about-two__count-text">
                                        Suscriptores
                                        <span className="counter">
                      <VisibilitySensor
                          onChange={this.onVisibilityChange}
                          offset={{top: 10}}
                          delayedCall
                      >
                        <CountUp end={this.state.startCounter ? 150 : 0}/>
                      </VisibilitySensor>
                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
